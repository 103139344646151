import React from "react"
import {Link, navigate} from "gatsby";
import {StyledLink} from "./layout";
import {FiFacebook, FiInstagram, FiMail} from 'react-icons/fi';
import {useIsMobile} from "../utils";


const Footer = () => {
    const {isMobile} = useIsMobile();
    return (
        <div
            style={{
                background: '#000',
                display: "flex",
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: "center",
                justifyContent: "space-between",
                padding:
                    "2rem 5rem",
                color: '#ece4d9'
            }}
        >
            <div style={{display: 'flex',
                fontFamily: 'Montserrat',
        }}>
                <div style={{
                    flexBasis: 0,
                    cursor: 'pointer',
                    flexGrow: 1, alignItems: 'center', justifyContent: 'center', display: 'flex', marginRight: 24
                }}>
                    <img src={"/logo.png"} style={{height: 80}}
                    />
                </div>
                <div>
                    <div style={{
                        fontWeight: 'bold',
                    }}>
                        Cooperation 合作咖啡
                    </div>
                    <div style={{ fontSize: 14}}>
                        地址 : 台中市福田三街165號<br/>
                        電話 : 04 2263 3221<br/>
                        營業 : 上午11:00 - 下午7:00
                    </div>
                </div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                margin: isMobile ? 24 : 0,
                alignItems: 'center',
                fontFamily: 'Montserrat',
            }}>
                {
                    isMobile ? [
                            <StyledLink title=" ABOUT " link="/about"/>,
                            <div style={{height: 8}}/>,
                            <StyledLink title="GUIDES" link="/guides"/>,
                            <div style={{height: 8}}/>,
                            <StyledLink title=" SHOP " link="/shop"/>,
                            <div style={{height: 8}}/>,
                            <StyledLink title=" SUBSCRIBE " link="/subscribe"/>,
                            <div style={{height: 8}}/>,
                            <StyledLink title=" WHOLESALE " link="/wholesale"/>,
                            <div style={{height: 8}}/>,
                            <StyledLink title=" FAQ " link="/faq"/>
                        ] :
                        <div style={{display: 'flex'}}>
                            <StyledLink title=" ABOUT " link="/about"/>
                            <div style={{width: 24}}/>
                            <StyledLink title=" SHOP " link="/shop"/>
                            <div style={{width: 24}}/>
                            <StyledLink title=" SUBSCRIBE " link="/subscribe"/>
                            <div style={{width: 24}}/>
                            <StyledLink title=" WHOLESALE " link="/wholesale"/>
                            <div style={{width: 24}}/>
                            <StyledLink title=" FAQ " link="/faq"/>
                        </div>
                }
                <div style={{width: 32, height: 24}}></div>
                <div
                    style={{display: 'flex', alignItems: 'center'}}
                >
                    <img 
                        src="/facebook.svg"
                        style={{ cursor: 'pointer'}}
                        onClick={() => {
                            window.open('https://www.facebook.com/cooperationcoffee/', '_blank').focus();
                        }}
                     />
                    <div style={{width: 10}}></div>
                    <img 
                        src="/instagram.svg"
                        style={{ cursor: 'pointer'}}
                        onClick={() => {
                            window.open('https://www.instagram.com/cooperation.coffee/', '_blank').focus();
                        }}
                     />
                    <div style={{width: 10}}></div>
                    <img 
                        src="/email.svg"
                        style={{ cursor: 'pointer'}}
                        onClick={() => {
                            window.open('mailto:cooperation.coffee@gmail.com', '_self' );
                        }}
                     />
                </div>
            </div>
        </div>
    )
}

export default Footer;
