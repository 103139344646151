import React, {useEffect, useLayoutEffect} from "react"
import {Link, useStaticQuery, graphql, navigate} from "gatsby"
import HeadMenu from "./head-menu";
import Footer from "./footer";
import {Sidebar} from "./sidebar";
import {useIsMobile} from "../utils";
import {useSpring} from "framer-motion";


export const StyledLink = ({link, title}) => {

    const spring = useSpring(0, {damping: 300, stiffness: 1000});

    useLayoutEffect(() => {
        spring.onChange(latest => {
            window.scrollTo(0, latest);
        });
    }, [spring]);

    function moveTo(to) {
        spring.set(window.pageYOffset, false);
        return new Promise((resolve) => {
            setTimeout(() => {
                spring.set(to);
                resolve(null);
            }, 50);
        });
    }
    return <span onClick={async () => {
        await moveTo(0);
        navigate(link);
    }} style={{
        color: '#fff'
    }}>{title}</span>;
}

export const BlackLink = ({link, title}) =>
    <Link to={link} style={{
        color: '#000',
        textDecoration: "none",

    }}>{title}</Link>

export const SegmentLink = ({link, children}) =>
    <Link to={link} style={{
        color: '#000',
        textDecoration: "none",
    }}>{children}</Link>


const Item = ({title, link, active}) => {
    return (
        <div
        >
            <Link to={link} style={{
                color: '#fff',
                textDecoration: 'none'
            }}>{title}</Link>
        </div>
    )
}

const Layout = ({isHomePage, children, backgroundColor = '#F5EEE0'}) => {
    const {screenWidth, isMobile, screenHeight} = useIsMobile();

    if (typeof  window === 'undefined') {
        return <></>;
    }

    return (
        <div style={{
                  position: 'fixed',
                  top: 0,
                  bottom: 0,
        }}>
            {
                screenWidth < 1160 &&
                <Sidebar>
                    <Item title="ABOUT" link="/about" active={true}/>,
                    <Item title="GUIDES" link="/guides" active={true}/>,
                    <Item title="SHOP" link="/shop" active={true}/>,
                    <Item title="SUBSCRIBE" link="/subscribe" active={true}/>,
                    <Item title="WHOLESALE" link="/wholesale" active={true}/>,
                    <Item title="FAQ" link="/faq" active={true}/>
                </Sidebar>
            }
            <HeadMenu />
            <div className="global-wrapper" data-is-root-path={isHomePage} style={{padding: 0, minHeight: screenHeight -346 }}>
              <main
                style={{
                  position: 'fixed',
                  top: 96,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  backgroundColor,
          overflow: 'auto'
                  
                }}
              >
                <div
                  style={{ minHeight: screenHeight - 247 }}
                >
                  {children}
                </div>
            <Footer/>
            </main>

                {/*<footer>*/}
                {/*  © {new Date().getFullYear()}, Built with*/}
                {/*  {` `}*/}
                {/*  <a href="https://www.gatsbyjs.com">Gatsby</a>*/}
                {/*  {` `}*/}
                {/*  And <a href="https://wordpress.org/">WordPress</a>*/}
                {/*</footer>*/}
            </div>
        </div>
    )
}

export default Layout
