import React, {useEffect, useState} from "react"
import {Link, navigate} from "gatsby";
import {FiShoppingCart} from 'react-icons/fi';
import { useCart } from "../cart";
import {useIsMobile} from "../utils";

const Item = ({title, link, active}) => {
    return (
        <div
        >
            <Link to={link} style={{
                color: '#fff',
                textDecoration: 'none',
                fontSize: 14,
            }}>{title}</Link>
        </div>
    )
}

const Navigation = () => {
    const { screenWidth } = useIsMobile();
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                flexGrow: 1,
                flexBasis: 0,
            }}
        >
            {
                screenWidth < 1160? [
                ] : [
                    <Item title="ABOUT" link="/about" active={true} key="navi-about"/>,
                    <Item title="GUIDES" link="/guides" active={true} key="navi-shop"/>,
                    <Item title="SHOP" link="/shop" active={true} key="navi-shop"/>,
                    <Item title="SUBSCRIBE" link="/subscribe" active={true} key="navi-subscribe"/>,
                    <Item title="WHOLESALE" link="/wholesale" active={true} key="navi-wholesale"/>,
                    <Item title="FAQ" link="/faq" active={true} key="navi-faq"/>
                ]
            }
        </div>
    );
}

const HeadMenu = () => {
    const [initialized, setInitialized] = useState(false);
    const { itemCount, fetchCheckout, checkoutId } = useCart();
    useEffect(() => {
        if (checkoutId && !initialized) {
            setInitialized(true);
            fetchCheckout();
        }
    }, [checkoutId]);

    return (
        <div
            style={{
                color: '#ffffff',
                background: '#000000',
                display: "flex",
                alignItems: "center",
                padding: "16px 48px",
                fontFamily: 'Montserrat',
                position: 'fixed',
                top: 0,
                right: 0,
                left: 0
            }}
        >
            <Navigation/>
            <div style={{
                flexBasis: 0,
                cursor: 'pointer',
                flexGrow: 0.5, alignItems: 'center', justifyContent:　'center', display: 'flex'}}>
                <img src={"/logo.png"} style={{width: 80}} onClick={() => {
                    navigate('/');
                }}/>
            </div>
            <div style={{
                flexBasis: 0,
                display: 'flex', flexGrow: 1, height: 20, justifyContent: 'end'}}>
                <div onClick={() => {
                    navigate('/cart');
                }}
                      style={{ color: '#ffffff', textDecoration: 'none', cursor: 'pointer' }}>
                    <FiShoppingCart size={24}/>
                </div>
            </div>
        </div>
    )
}

export default HeadMenu;
