class LineItem {
    public  id: string;
    public  title: string;
    public  engName: string;
    public  spec: string;
    public  url: string;
    public  imageUrl: string;
    public  productType: string;
    public  count: number;
    public  price: number;
    public  customAttributes: any[];
    constructor(
       data: Partial<LineItem>
    ) {
        Object.assign(this, data);
    }
    static fromShopify({ node }): LineItem {

        const metafields = (node?.variant.product?.metafields?.edges ?? []).reduce((acc, { node }) => {
          if (node.key === '_long_description') {
            acc.detailText = node.value;
          } else if (node.key === 'eng_name') {
            acc.engName = node.value;
          }
          return acc;
        }, {});

        const productType = node.variant.product.productType;

        const getSpec = () => {
          if (productType === '咖啡豆') {
            const s = node.variant.title.replace('單賣 /', '');
            if (s.includes('包')) {
              return `掛耳 ${s}`;
            }
            return s;
          }

          return node.variant.title;
        }
        const spec = getSpec();

        return new LineItem({
            id: node.id,
            title: node.title,
            spec,
            url: `/products/${node.variant.product.handle}`,
            productType: node.variant.product.productType,
            price: node.quantity * parseInt(node.variant.priceV2.amount || 0),
            imageUrl: node.variant.image.url,
            customAttributes: node.customAttributes,
            count: node.quantity,
            ...metafields,
        })
    }
}

export class Checkout {
    public id: string;
    public webUrl: string;
    public items?: LineItem[];
    public price: number;


    constructor(data : any = {}) {
        Object.assign(this, data);
    }

    static fromShopify({ node }): Checkout {
        const items = (node?.lineItems?.edges ?? []).map((edge) =>
LineItem.fromShopify(edge)
        )
        return new Checkout({
            id: node.id,
            items,
            webUrl: node.webUrl,
            price: parseInt(node.subtotalPriceV2.amount || 0),
        });
    }
}
