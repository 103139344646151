import Layout from "./src/components/layout"
// normalize CSS across browsers
import "./src/css/normalize.css"

// custom CSS styles
import "./src/css/style.css"
import React from "react"
import {AnimatePresence} from "framer-motion";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const wrapPageElement = ({ element, props }) => {
    // console.log("wrapPageElement", props);
    return (
        <>
            <AnimatePresence>
              <Layout>
                {element}
              </Layout>
            </AnimatePresence>
            <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}

export const shouldUpdateScroll = ({
}) => {
  document.querySelector('main').scrollTo(0,0);
  return true;
}
