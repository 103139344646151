import React, {useState} from "react";
import {motion, useSpring} from "framer-motion";

const Path = (props: any) => {
    return (
        <motion.path
            fill="transparent"
            stroke="inherit"
            strokeWidth="3"
            strokeLinecap="round"
            {...props}
        />
    );
};

export const SideBarIcon = (props: any) => {
    return (
        <motion.div
            initial={false}
            style={{stroke: '#fff'}}
            animate={props.visible ? "open" : "closed"}
        >
            <svg width="23" height="23" viewBox="0 0 23 23">
                <Path
                    variants={{
                        closed: {d: "M 2 2.5 L 20 2.5"},
                        open: {d: "M 3 16.5 L 17 2.5"}
                    }}
                />
                <Path
                    d="M 2 9.423 L 20 9.423"
                    variants={{
                        closed: {opacity: 1},
                        open: {opacity: 0}
                    }}
                    transition={{duration: 0.1}}
                />
                <Path
                    variants={{
                        closed: {d: "M 2 16.346 L 20 16.346"},
                        open: {d: "M 3 2.5 L 17 16.346"}
                    }}
                />
            </svg>
        </motion.div>
    );
};
const Line = ({ width }) => (
    <div style={{
        backgroundColor: '#fff',
        width,
        height: 2,
        margin: '2px 14px',
        borderRadius: 8,
    }}></div>
)

const HamburgerButton = ({x, width, isOpen, setOpen}) => {
    return (
        <div
            style={{
                borderRadius: 32,
                width: 48,
                height: 48,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                position: 'fixed',
                top: -12,
                margin: 36,
                cursor: 'pointer',
                zIndex: 101,
            }}
            onClick={() => {
                console.log(isOpen)
                setOpen(!isOpen)
                isOpen ? x.set(-width) : x.set(0)
            }}
        >
            <Line width={16}/>
            <Line width={12}/>
        </div>
    )
}

export const Sidebar = ({width = 320, color = "#000", children}) => {
    const [isOpen, setOpen] = useState(false)
    const x = useSpring(0, {stiffness: 400, damping: 40})

    return (
        <div
            style={{
                fontFamily: 'Montserrat',
            }}
        >
            <HamburgerButton x={x} width={width} isOpen={isOpen} setOpen={setOpen}/>
            <motion.div
                style={{
                    position: 'fixed',
                    backgroundColor: color,
                    width: width,
                    height: '100%',
                    zIndex: 100,
                    boxSizing: 'border-box',
                    ...(
                        isOpen ? {
                            boxShadow: '16px 0 32px -16px #000',
                        } : {}
                    ),
                    padding: '128px 64px 64px 48px',
                    x,
                }}
                transition={{
                    type: "spring",
                    stiffness: 400,
                    damping: 40
                }}
                initial={{x: -width}}
            >
                {children}
            </motion.div>
        </div>
    )
}
