import React, {useEffect, useState } from "react"
import { createGlobalState } from 'react-hooks-global-state';


import { client, shopify } from "./client";
import {navigate} from "gatsby";
import { Checkout } from "./models/checkout";
const { useGlobalState } = createGlobalState({
    checkoutId: '',
    itemCount: 0,
    checkout: null,
});


const useCart = () => {
    const [checkoutId, setCheckoutId] = useGlobalState('checkoutId');
    const [checkout, setCheckout] = useGlobalState('checkout');
    const [itemCount, setItemCount] = useGlobalState('itemCount');

    const fetchCheckout = async (id = '') => {
        if (!id && !checkoutId) return null;
        const data = await client.request(`
          query {
            node(id: "${id || checkoutId}") {
              ...on Checkout {
                subtotalPriceV2 {
                    amount
                }
                webUrl
                order {
                    id
                }
                lineItems(first: 100) {
                    edges {
                        node {
                            id
                            title
                            quantity
                            customAttributes {
                              key
                              value
                            }
                            variant {
                                title
                                image {
                                    url
                                }
                                priceV2 {
                                    amount
                                }
                                product {
                                    handle
                                    productType
                                    metafields(first: 30) {
                                      edges {
                                        node {
                                          id
                                          value
                                          key
                                        }
                                      }
                                    }
                                }
                            }
                            unitPrice {
                                amount
                            }
                        }
                    }
                }
              }
            }
          }
        `);
        if (!data?.node || data?.node?.order) {
            return createCheckout();
        }
        const checkout = Checkout.fromShopify(data);

        if (checkout?.items?.length) {
            setItemCount(checkout?.items?.length);
        }
        setCheckout(checkout);
        return checkout;
    }

    const createCheckout = async () => {
        const checkout = await shopify.checkout.create();
        localStorage.setItem('checkout', checkout.id);
        setCheckoutId(checkout.id);
        setCheckout(checkout);
        return checkout;
    }

    useEffect(() => {
        let localCheckoutId = localStorage.getItem('checkout');
        console.log("local", localCheckoutId);
        if (localCheckoutId) {
            setCheckoutId(localCheckoutId);
        }
    })


    const addItem = async (items) => {
        setItemCount(itemCount + 1)
        if (!checkoutId) {
            const checkout = await createCheckout();
            await shopify.checkout.addLineItems(checkout.id, items);
        } else {
            await shopify.checkout.addLineItems(checkoutId, items);
        }
        fetchCheckout();
    };

    const removeItem = async  (id) => {
        await shopify.checkout.removeLineItems(checkoutId, [id]);
        fetchCheckout();
    }

    const goCheckout = async () => {
    }


    return {
        addItem,
        checkoutId,
        itemCount,
        fetchCheckout,
        createCheckout,
        goCheckout,
        removeItem,
        checkout,
    }
}

export { useCart }
